import { ContentNavMenu, NavContent, ContentLoader } from "#components";

export default defineComponent({
  name: "SaveContextMenuContent",
  props: {
    remember: { type: Boolean, default: false },
    menu: { type: Array as PropType<SaveContextMenuItem[]>, required: true },
  },
  setup(props) {
    const nav = ref<string | number>();
    const { fetching, loaded } = useSaveContext<any>();
    const showContent = computed(() => {
      if (!nav.value) {
        return false;
      }
      if (fetching.value) {
        const item = props.menu.find((m) => m.key == nav.value);
        return !!item?.primary;
      }
      return loaded.value;
    });

    const open = (key: string | number) => {
      nav.value = key;
    };

    return {
      nav,
      fetching,
      showContent,
      open,
    };
  },
  render() {
    return h("div", { class: "save-context-content" }, [
      h(ContentNavMenu, {
        class: "save-context-content__menu mb-4",
        loading: this.fetching,
        menu: this.menu,
        remember: this.remember,
        modelValue: this.nav,
        "onUpdate:modelValue": (v: string | number) => {
          this.nav = v;
        },
      }),
      h(
        ContentLoader,
        { loading: !this.showContent },
        {
          default: () => {
            return h(NavContent, {
              class: "save-context-content__content",
              menu: this.menu,
              nav: this.nav,
            });
          },
        }
      ),
    ]);
  },
});
