import { isFunction, isObject } from "lodash";
import type { Component } from "vue";

export type SaveContextMenuItem = {
  title: string;
  key?: string | number;
  icon?: string;
  color?: string;
  component: Component | string;
  primary?: boolean;
  loading?: boolean;
  visible?: { (): boolean };
  props?: any;
};

export const defineSaveContextMenu = <T extends SaveContextMenuItem>(
  items: T[] | { (): T[] }
) => {
  return computed(() => {
    const x = isFunction(items) ? items() : items;
    const _items = x.map((item) => {
      if (isObject(item.component)) {
        item.component = markRaw(item.component);
      }
      return item;
    });

    return _items.filter((item) => {
      return !item.visible || (item.visible && item.visible());
    });
  });
};
